<template>
  <v-dialog
    v-model="modalMasterStatus"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
    transition="scale-transition"
    scrollable
    width="1200"
    @click:outside="closeDialog()"
  >
    <v-card outlined id="form">
      <v-card-title class="subtitle-1 font-weight-bold">
        {{ title }}
        <span v-if="formType != 'weight' && competencies" class="ml-2"
          >{{ competencies.lesson }}
          <span>{{ competencies.sub_lesson }}</span></span
        >
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-form
          ref="formCoreCompetencies"
          v-model="masterCoreCompetenciesForm"
          lazy-validation
        >
          <v-sheet class="my-3">
            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="data.school_year"
                  :rules="[v => !!v || $t('app.required')]"
                  :label="$t('core_competencies.choose_school_year')"
                  :items="listMaster.schoolYearList"
                  item-value="id"
                  item-text="semester"
                  :loading="loadingMaster.schoolYear"
                  :disabled="formType == 'edit' || isEditWeight"
                  dense
                  outlined
                  required
                >
                  <template #item="{ item }">
                    {{ item.start_year }} / {{ item.end_year }}, semester
                    {{ item.semester }}
                    <v-tooltip right>
                      <template #activator="{ on }">
                        <v-icon
                          v-on="on"
                          :color="schoolYearInfo(item.status).color"
                          small
                          class="ml-1"
                          >{{ schoolYearInfo(item.status).name }}</v-icon
                        >
                      </template>
                      <span>{{ schoolYearInfo(item.status).info }}</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index === 0" class="mr-2">{{
                      `${item.start_year} / ${item.end_year}, semester ${item.semester}`
                    }}</span>
                    <span v-if="index === 1" class="grey--text">
                      (+{{ filter.school_year.length - 1 }}
                      {{ $t("app.others") }})
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="data.grade"
                  :rules="[v => !!v || $t('app.required')]"
                  :label="$t('core_competencies.choose_grade')"
                  :items="listMaster.gradeList"
                  item-value="id"
                  item-text="grade"
                  :loading="loadingMaster.grade"
                  :disabled="formType == 'edit' || isEditWeight"
                  :multiple="multipleGrade"
                  dense
                  outlined
                  required
                />
              </v-col>
              <!-- <v-col cols="12">
                <v-select
                  v-if="formType == 'edit'"
                  v-model="data.subject"
                  :rules="[v => !!v || $t('app.required')]"
                  :label="$t('core_competencies.choose_subject')"
                  :disabled="subjectList.length == 0 || formType == 'edit'"
                  :items="subjectList"
                  :item-text="t => t.lesson + ' ' + (t.sub || '')"
                  :loading="loadingSubject"
                  item-value="id_lesson"
                  dense
                  outlined
                  required
                />
              </v-col> -->
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card
                  v-for="(r, i) in data.ki"
                  :key="i"
                  class="pa-3 mb-4 elevation-1"
                >
                  <v-row justify="start" align="center">
                    <v-col cols="12">
                      <v-text-field
                        v-model="r.competencies"
                        :rules="[v => !!v || $t('app.required')]"
                        :label="$t('core_competencies.ki')"
                        dense
                        outlined
                        required
                      />
                      <v-textarea
                        v-if="formType !== 'weight'"
                        v-model="r.desc"
                        :label="$t('core_competencies.description')"
                        class="mb-4"
                        outlined
                        auto-grow
                        dense
                        rows="1"
                        hide-details
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                      <v-row no-gutters align="center" justify="start">
                        <v-switch
                          v-model="r.has_weighting"
                          :label="$t('core_competencies.weighting')"
                          style="max-width: 150px"
                          class="mt-0 mb-2"
                          dense
                          hide-details
                        ></v-switch>
                        <p
                          v-if="totalWeight(i) != 0"
                          class="ml-6 body-2 mt-3"
                          :class="totalWeight(i) > 100 ? 'error--text' : ''"
                        >
                          {{ totalWeight(i) }}
                          <v-icon
                            small
                            :color="totalWeight(i) > 100 ? 'error' : ''"
                            >mdi-percent</v-icon
                          >
                        </p>
                        <v-spacer></v-spacer>
                        <v-alert
                          v-if="totalWeight(i) != 0 && totalWeight(i) != 100"
                          color="error"
                          dense
                          text
                          class="ml-6 caption"
                        >
                          <span>{{
                            $t("core_competencies.max_weighting")
                          }}</span>
                        </v-alert>

                        <v-alert
                          v-if="r.totalCombineError"
                          color="error"
                          dense
                          text
                          class="ml-2 caption"
                        >
                          <span>{{
                            $t("core_competencies.max_weighting_uh")
                          }}</span>
                        </v-alert>
                      </v-row>
                    </v-col>
                    <v-row v-if="r.has_weighting" class="mx-4">
                      <v-tabs
                        v-model="r.formula"
                        prev-icon="null"
                        @change="changeFormula(i)"
                      >
                        <v-tabs-slider color="primary"></v-tabs-slider>
                        <v-tab>{{ $t("core_competencies.formula1") }}</v-tab>
                        <v-tab>{{ $t("core_competencies.formula2") }}</v-tab>
                      </v-tabs>

                      <v-card
                        flat
                        style="border: 2px solid #27ace2; width: 100%; border-top-left-radius: 0px;"
                        class="pa-3 caption"
                      >
                        <v-tabs-items v-model="r.formula">
                          <!-- Formula 1 -->
                          <v-tab-item>
                            <v-card flat>
                              <v-row>
                                <v-col cols="12">
                                  <v-row no-gutters>
                                    <v-checkbox
                                      v-model="r.midterm_exams"
                                      :label="
                                        $t('core_competencies.midterm_exam')
                                      "
                                      class="mt-0"
                                      hide-details
                                      @change="
                                        handleCheckbox('midterm_exams', i)
                                      "
                                    />
                                    <v-text-field
                                      v-if="r.midterm_exams"
                                      v-model="r.midterm_exams_weighting"
                                      :rules="[v => !!v || $t('app.required')]"
                                      append-icon="mdi-percent"
                                      class="ml-2"
                                      dense
                                      outlined
                                      required
                                      max-length="3"
                                      style="min-width: 100px; max-width: 200px"
                                      @keypress="isNumberOnly($event)"
                                      @change="
                                        maxLength(
                                          'midterm_exams_weighting',
                                          i,
                                          r.midterm_exams_weighting
                                        );
                                        setTotalValue(i);
                                      "
                                    />
                                  </v-row>
                                </v-col>
                                <v-col cols="12">
                                  <v-row no-gutters>
                                    <v-checkbox
                                      v-model="r.semester_exams"
                                      :label="
                                        $t('core_competencies.final_exams')
                                      "
                                      class="mt-0"
                                      hide-details
                                      @change="
                                        handleCheckbox('semester_exams', i)
                                      "
                                    />
                                    <v-text-field
                                      v-if="r.semester_exams"
                                      v-model="r.semester_exams_weighting"
                                      :rules="[v => !!v || $t('app.required')]"
                                      append-icon="mdi-percent"
                                      class="ml-2"
                                      dense
                                      outlined
                                      required
                                      max-length="3"
                                      style="min-width: 100px; max-width: 200px"
                                      @keypress="isNumberOnly($event)"
                                      @change="
                                        maxLength(
                                          'semester_exams_weighting',
                                          i,
                                          r.semester_exams_weighting
                                        );
                                        setTotalValue(i);
                                      "
                                    />
                                  </v-row>
                                </v-col>

                                <v-col cols="12">
                                  <v-row no-gutters>
                                    <v-checkbox
                                      v-model="r.total_daily"
                                      :label="
                                        $t('core_competencies.total_formula1')
                                      "
                                      class="mt-0"
                                      hide-details
                                      @change="
                                        () => {
                                          handleCheckbox('total_daily', i);
                                          resetFromTotal(i);
                                          setTotalValue(i);
                                        }
                                      "
                                    />
                                    <v-text-field
                                      v-if="r.total_daily"
                                      v-model="r.total_daily_weighting"
                                      :rules="[v => !!v || $t('app.required')]"
                                      append-icon="mdi-percent"
                                      class="ml-2"
                                      dense
                                      outlined
                                      required
                                      max-length="3"
                                      style="min-width: 100px; max-width: 200px"
                                      @keypress="isNumberOnly($event)"
                                      @change="
                                        maxLength(
                                          'total_daily_weighting',
                                          i,
                                          r.total_daily_weighting
                                        )
                                      "
                                    />
                                  </v-row>

                                  <v-row
                                    v-if="r.total_daily"
                                    justify="center"
                                    class="ml-8"
                                  >
                                    <v-col cols="12">
                                      <v-row no-gutters>
                                        <v-checkbox
                                          v-model="r.homework"
                                          :label="
                                            $t('core_competencies.homework')
                                          "
                                          class="mt-0"
                                          hide-details
                                          @change="
                                            handleCheckbox('homework', i)
                                          "
                                        />
                                        <v-text-field
                                          v-if="r.homework"
                                          v-model="r.homework_weighting"
                                          :rules="[
                                            v => !!v || $t('app.required')
                                          ]"
                                          append-icon="mdi-percent"
                                          class="ml-2"
                                          dense
                                          outlined
                                          required
                                          max-length="3"
                                          style="min-width: 100px; max-width: 200px"
                                          @keypress="isNumberOnly($event)"
                                          @blur="checkTotal(r, i)"
                                          @change="
                                            maxLengthTotal(
                                              'homework_weighting',
                                              i,
                                              r.homework_weighting
                                            )
                                          "
                                        />
                                      </v-row>
                                    </v-col>

                                    <v-col cols="12">
                                      <v-row no-gutters>
                                        <v-checkbox
                                          v-model="r.daily"
                                          :label="
                                            $t('core_competencies.daily_tests')
                                          "
                                          class="mt-0"
                                          hide-details
                                          @change="handleCheckbox('daily', i)"/>

                                        <v-text-field
                                          v-if="r.daily"
                                          v-model="r.daily_weighting"
                                          :rules="[
                                            v => !!v || $t('app.required')
                                          ]"
                                          class="ml-2"
                                          append-icon="mdi-percent"
                                          dense
                                          outlined
                                          required
                                          max-length="3"
                                          style="min-width: 100px; max-width: 200px"
                                          @keypress="isNumberOnly($event)"
                                          @blur="checkTotal(r, i)"
                                          @change="
                                            maxLengthTotal(
                                              'daily_weighting',
                                              i,
                                              r.daily_weighting
                                            )
                                          "
                                      /></v-row>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-tab-item>

                          <!-- Formula 2 -->
                          <v-tab-item>
                            <v-card flat>
                              <v-row>
                                <v-col cols="6">
                                  <v-row no-gutters>
                                    <v-checkbox
                                      v-model="r.midterm_exams_2"
                                      :label="
                                        $t('core_competencies.midterm_exam')
                                      "
                                      class="mt-0"
                                      hide-details
                                      @change="
                                        handleCheckbox('midterm_exams_2', i)
                                      "
                                    />
                                    <v-text-field
                                      v-if="r.midterm_exams_2"
                                      v-model="r.midterm_exams_2_weighting"
                                      :rules="[v => !!v || $t('app.required')]"
                                      append-icon="mdi-percent"
                                      class="ml-2"
                                      dense
                                      outlined
                                      required
                                      max-length="3"
                                      style="min-width: 100px; max-width: 200px"
                                      @keypress="isNumberOnly($event)"
                                      @change="
                                        maxLength(
                                          'midterm_exams_2_weighting',
                                          i,
                                          r.midterm_exams_2_weighting
                                        )
                                      "
                                    />
                                  </v-row>
                                </v-col>
                                <v-col cols="6">
                                  <v-row no-gutters>
                                    <v-checkbox
                                      v-model="r.semester_exams_2"
                                      :label="
                                        $t('core_competencies.final_exams')
                                      "
                                      class="mt-0"
                                      hide-details
                                      @change="
                                        handleCheckbox('semester_exams_2', i)
                                      "
                                    />
                                    <v-text-field
                                      v-if="r.semester_exams_2"
                                      v-model="r.semester_exams_2_weighting"
                                      :rules="[v => !!v || $t('app.required')]"
                                      append-icon="mdi-percent"
                                      class="ml-2"
                                      dense
                                      outlined
                                      required
                                      max-length="3"
                                      style="min-width: 100px; max-width: 200px"
                                      @keypress="isNumberOnly($event)"
                                      @change="
                                        maxLength(
                                          'semester_exams_2_weighting',
                                          i,
                                          r.semester_exams_2_weighting
                                        )
                                      "
                                    />
                                  </v-row>
                                </v-col>
                                <v-col cols="6">
                                  <v-row no-gutters>
                                    <v-checkbox
                                      v-model="r.homework_2"
                                      :label="$t('core_competencies.homework')"
                                      class="mt-0"
                                      hide-details
                                      @change="handleCheckbox('homework_2', i)"
                                    />
                                    <v-text-field
                                      v-if="r.homework_2"
                                      v-model="r.homework_2_weighting"
                                      :rules="[v => !!v || $t('app.required')]"
                                      append-icon="mdi-percent"
                                      class="ml-2"
                                      dense
                                      outlined
                                      required
                                      max-length="3"
                                      style="min-width: 100px; max-width: 200px"
                                      @keypress="isNumberOnly($event)"
                                      @change="
                                        maxLength(
                                          'homework_2_weighting',
                                          i,
                                          r.homework_2_weighting
                                        )
                                      "
                                    />
                                  </v-row>
                                </v-col>

                                <v-col cols="6">
                                  <v-row no-gutters>
                                    <v-checkbox
                                      v-model="r.daily_2"
                                      :label="
                                        $t('core_competencies.daily_tests')
                                      "
                                      class="mt-0"
                                      hide-details
                                      @change="handleCheckbox('daily_2', i)"/>

                                    <v-text-field
                                      v-if="r.daily_2"
                                      v-model="r.daily_2_weighting"
                                      :rules="[v => !!v || $t('app.required')]"
                                      class="ml-2"
                                      append-icon="mdi-percent"
                                      dense
                                      outlined
                                      required
                                      max-length="3"
                                      style="min-width: 100px; max-width: 200px"
                                      @keypress="isNumberOnly($event)"
                                      @change="
                                        maxLength(
                                          'daily_2_weighting',
                                          i,
                                          r.daily_2_weighting
                                        )
                                      "
                                  /></v-row>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-tab-item>
                        </v-tabs-items>
                      </v-card>
                    </v-row>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-sheet>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-layout justify-end class="ma-5">
          <v-btn class="mr-2" outlined color="primary" @click="closeDialog()">{{
            $t("app.back")
          }}</v-btn>
          <v-btn
            :loading="loadingSave"
            class="gradient-primary"
            dark
            depressed
            @click="submit"
            >{{ $t("app.save") }}</v-btn
          >
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment/moment";
moment.locale("id");

import { save_competencies } from "@/api/admin/academic/coreCompetencies";

import { isNumberOnly } from "@/utils/validate";

export default {
  props: {
    title: String,
    formType: String,
    modalMasterStatus: Boolean,
    toggleDialogMaster: Function,
    loadingMaster: Object,
    changeFormType: Function,
    listMaster: Object,
    competencies: Object,
    filter: Object,
    multipleGrade: Boolean,
    tempData: Object
  },
  data() {
    return {
      modalStart: false,
      modalEnd: false,
      loadingSubject: false,
      masterCoreCompetenciesForm: false,
      subjectList: [],
      totalWeighting: 0,
      loadingSave: false,
      isEditWeight: false,
      data: {
        subject: "",
        school_year: "",
        grade: "",
        ki: []
      }
    };
  },
  watch: {
    async modalMasterStatus(n) {
      this.isEditWeight = false;
      this.data = {
        subject: "",
        school_year: this.filter.school_year ? this.filter.school_year : "",
        grade: this.filter.grade ? this.filter.grade : "",
        ki: []
      };

      if (this.formType == "weight") {
        const getActiveSchoolYear = this.listMaster.schoolYearList.find(
          r => r.status == 1
        );
        if (getActiveSchoolYear) this.data.school_year = getActiveSchoolYear.id;
      }

      this.setDataKI();

      if (n) {
        if (this.competencies && this.formType == "edit") {
          const n = this.competencies;
          this.data.school_year = Number(n.school_year);
          this.data.grade = Number(n.id_grade);

          n.ki.map(async (r, i) => {
            // get Bobot
            const filterBobot = param =>
              r.bobot.filter(r => r.key == param).length > 0
                ? r.bobot.filter(r => r.key == param)[0].value
                : 0;

            // check if has weighting
            const has_weighting = [];
            const ki = this.data.ki[i];
            await Promise.all(
              r.bobot.map(b => {
                if (b.value) has_weighting.push(b);
              })
            );

            // get rest midterm_exams semester_exams calculation
            let total = 0;
            if (filterBobot("MIDTERM_EXAM") || filterBobot("SEMESTER_EXAM")) {
              total =
                100 -
                (filterBobot("MIDTERM_EXAM") + filterBobot("SEMESTER_EXAM"));
            } else if (filterBobot("DAILY_TEST") || filterBobot("HOMEWORK")) {
              total = 100;
            }

            ki.competencies = r.competencies;
            ki.desc = r.desc;
            ki.has_weighting = has_weighting.length > 0;
            ki.formula = r.formula_tab == null ? 0 : r.formula_tab;
            if (r.formula_tab == 0) {
              ki.total = filterBobot("MIDTERM_EXAM") ? 100 : 0;
              ki.daily = filterBobot("DAILY_TEST") ? true : false;
              ki.homework = filterBobot("HOMEWORK") ? true : false;
              ki.midterm_exams = filterBobot("MIDTERM_EXAM") ? true : false;
              ki.semester_exams = filterBobot("SEMESTER_EXAM") ? true : false;
              ki.total_daily = total ? true : false;
              ki.total_daily_weighting = total;
              ki.daily_weighting = filterBobot("DAILY_TEST");
              ki.homework_weighting = filterBobot("HOMEWORK");
              ki.midterm_exams_weighting = filterBobot("MIDTERM_EXAM");
              ki.semester_exams_weighting = filterBobot("SEMESTER_EXAM");
              ki.totalCombineError = false;
            } else {
              ki.total_2 = filterBobot("MIDTERM_EXAM") ? 100 : 0;
              ki.daily_2 = filterBobot("DAILY_TEST") ? true : false;
              ki.homework_2 = filterBobot("HOMEWORK") ? true : false;
              ki.midterm_exams_2 = filterBobot("MIDTERM_EXAM") ? true : false;
              ki.semester_exams_2 = filterBobot("SEMESTER_EXAM") ? true : false;
              ki.daily_2_weighting = filterBobot("DAILY_TEST");
              ki.homework_2_weighting = filterBobot("HOMEWORK");
              ki.midterm_exams_2_weighting = filterBobot("MIDTERM_EXAM");
              ki.semester_exams_2_weighting = filterBobot("SEMESTER_EXAM");
              ki.totalCombineError_2 = false;
            }
          });
        }
      }

      setTimeout(() => {
        document.querySelector(".v-card").scrollIntoView();
      }, 200);
    },
    "data.grade"() {
      if (this.data.grade && this.data.school_year) {
        if (this.formType != "weight") {
          // this.loadingSubject = true;
          // this.subjectList = [];
          // const rSubjects = await get_lesson_ki({
          //   filter: {
          //     school_year: [this.data.school_year],
          //     grade: [n]
          //   }
          // });
          if (this.competencies) this.data.subject = this.competencies.id;
          // this.subjectList = rSubjects.data.data;
          // this.loadingSubject = false;
        }
      }
    }
  },
  methods: {
    changeFormula(idx) {
      console.log(this.data.ki[idx]);
    },
    setDataKI() {
      if (this.tempData) {
        this.data.grade = [this.tempData.grade];
        this.isEditWeight = true;
      }
      if (this.tempData && this.tempData.data.length > 0) {
        const data = [];
        this.tempData.data.map(r => {
          data.push({
            id: r.id,
            competencies: r.competencies,
            desc: r.description,
            has_weighting: r.formula ? true : false,
            formula: Number(r.formula),
            total: 0,
            daily: r.daily_test ? true : false,
            homework: r.homework ? true : false,
            midterm_exams: r.midterm ? true : false,
            semester_exams: r.semester ? true : false,
            total_daily: r.combine_value ? true : false,
            total_daily_weighting: r.combine_value,
            daily_weighting: r.formula == 0 ? r.daily_test : 0,
            homework_weighting: r.formula == 0 ? r.homework : 0,
            midterm_exams_weighting: r.formula == 0 ? r.midterm : 0,
            semester_exams_weighting: r.formula == 0 ? r.semester : 0,
            totalCombineError: false,
            total_2: 0,
            daily_2: r.daily_test ? true : false,
            homework_2: r.homework ? true : false,
            midterm_exams_2: r.midterm ? true : false,
            semester_exams_2: r.semester ? true : false,
            daily_2_weighting: r.formula == 1 ? r.daily_test : 0,
            homework_2_weighting: r.formula == 1 ? r.homework : 0,
            midterm_exams_2_weighting: r.formula == 1 ? r.midterm : 0,
            semester_exams_2_weighting: r.formula == 1 ? r.semester : 0,
            totalCombineError_2: false
          });
        });
        this.data.ki = data;
      } else {
        let formData = {
          desc: "",
          has_weighting: false,
          formula: 0,
          total: 0,
          daily: false,
          homework: false,
          midterm_exams: false,
          semester_exams: false,
          total_daily: false,
          total_daily_weighting: 0,
          daily_weighting: 0,
          homework_weighting: 0,
          midterm_exams_weighting: 0,
          semester_exams_weighting: 0,
          totalCombineError: false,
          total_2: 0,
          daily_2: false,
          homework_2: false,
          midterm_exams_2: false,
          semester_exams_2: false,
          daily_2_weighting: 0,
          homework_2_weighting: 0,
          midterm_exams_2_weighting: 0,
          semester_exams_2_weighting: 0,
          totalCombineError_2: false
        };

        this.data.ki = [
          {
            competencies: "SPIRITUAL",
            ...formData
          },
          {
            competencies: "SOSIAL",
            ...formData
          },
          {
            competencies: "PENGETAHUAN",
            ...formData
          },
          {
            competencies: "KETERAMPILAN",
            ...formData
          }
        ];
      }
    },
    schoolYearInfo(status) {
      let icon = { name: "", color: "", info: "" };
      const active = {
        name: "mdi-check-circle-outline",
        color: "success",
        info: this.$i18n.t("school_year.current_school_year")
      };
      const willCome = {
        name: "mdi-timer-sand",
        color: "grey",
        info: this.$i18n.t("school_year.upcoming_school_year")
      };

      if (this.isMaster) {
        switch (status) {
          case "ACTIVE":
            icon = active;
            break;
          case "WILLCOME":
            icon = willCome;
            break;
        }
      } else {
        switch (status) {
          case 1:
            icon = active;
            break;
          case 2:
            icon = willCome;
            break;
        }
      }
      return icon;
    },
    setTotalValue(idx) {
      const state = this.data.ki[idx];
      const total =
        Number(state.midterm_exams_weighting) +
        Number(state.semester_exams_weighting);

      this.$nextTick(() => {
        if (state.total_daily) state.total_daily_weighting = 100 - total;
      });
    },
    resetFromTotal(i) {
      const ki = this.data.ki[i];

      if (ki.formula == 0) {
        ki.totalCombineError = false;
        ki.homework = false;
        ki.homework_weighting = 0;
        ki.daily = false;
        ki.daily_weighting = 0;
      } else {
        ki.totalCombineError_2 = false;
        ki.homework_2 = false;
        ki.daily_2 = false;
        ki.homework_2_weighting = 0;
        ki.daily_2_weighting = 0;
      }
    },
    getClassName(classes) {
      if (classes.length) {
        return `(${classes.map(
          (className, index) => (index === 0 ? "" : " ") + `${className}`
        )})`;
      }
    },
    isNumberOnly: evt => isNumberOnly(evt),
    checkWeighting(data) {
      const wt = [];
      data.map(r => {
        if (r.value) wt.push(r.value);
      });
      return wt.length > 0;
    },
    hasValue(data, id) {
      return data.filter(r => r.id == id)[0].value != 0;
    },
    getValue(data, id) {
      return data.filter(r => r.id == id)[0].value
        ? data.filter(r => r.id == id)[0].value
        : 0;
    },
    handleCheckbox(state, i) {
      const d = this.data.ki[i];

      if (state == "total_daily") {
        d["homework_weighting"] = 0;
        d["daily_weighting"] = 0;
      }
      if (d[state] == false) {
        d[state + "_weighting"] = 0;
      }
    },
    maxLength(state, idx, value) {
      const d = this.data.ki[idx];
      const total =
        Number(d["midterm_exams_weighting"]) +
        Number(d["semester_exams_weighting"]);

      if (d.total <= 100 && d.total >= 0) {
        d[state] = value;
      } else if (d.total < 0) {
        d[state] = 0;
        d.total = 0;
      } else {
        if (
          state == "midterm_exams_weighting" ||
          state == "semester_exams_weighting"
        ) {
          if (total <= 100) {
            d[state] = value;
            this.setTotalValue(idx);
          } else d[state] = 0;
        } else d[state] = 0;
      }
    },
    maxLengthTotal(state, idx, value) {
      const d = this.data.ki[idx];
      const tempTotal =
        Number(d.homework_weighting) + Number(d.daily_weighting);

      if (tempTotal <= 100 && tempTotal >= 0) {
        d[state] = value;
      } else if (tempTotal >= 100) {
        d[state] = 0;
      } else {
        d[state] = 0;
      }
    },
    totalWeight(i) {
      const c = this.data.ki[i];
      let total = 0;
      if (c.formula == 0) {
        total =
          Number(c.midterm_exams_weighting) +
          Number(c.semester_exams_weighting) +
          Number(c.total_daily_weighting);
      } else {
        total =
          Number(c.daily_2_weighting) +
          Number(c.homework_2_weighting) +
          Number(c.midterm_exams_2_weighting) +
          Number(c.semester_exams_2_weighting);
      }
      if (c.formula == 0) {
        c.total = total;
      } else c.total_2 = total;

      return total;
    },
    checkTotal(data, i) {
      let total = 0;
      const c = this.data.ki[i];
      if (c.formula == 0) {
        total = Number(data.daily_weighting) + Number(data.homework_weighting);
        if (total != 100) this.data.ki[i].totalCombineError = true;
        else this.data.ki[i].totalCombineError = false;
      } else {
        total =
          Number(data.daily_2_weighting) + Number(data.homework_2_weighting);
        if (total != 100) this.data.ki[i].totalCombineError_2 = true;
        else this.data.ki[i].totalCombineError_2 = false;
      }
    },
    closeDialog() {
      this.toggleDialogMaster();
      this.$emit("getValue");
    },
    kiCondition(i, del) {
      const ki = this.data.ki;
      if (del) {
        if (i == 4) return i != 0;
        return ki.length > 0 && ki.length != 1;
      } else {
        return ki.length < 4 && i == ki.length - 1;
      }
    },
    setBobot(param) {
      const bobot = param ? Number(param) : 0;
      return bobot;
    },
    async submit() {
      const element = document.querySelector(".error--text");
      let checkTotal = 0;
      let checkTotalSub = 0;
      const condition = [];

      if (this.$refs.formCoreCompetencies.validate()) {
        Promise.all(
          this.data.ki.map((r, i) => {
            if (r.has_weighting) {
              if (r.formula == 0) {
                checkTotal =
                  Number(r.total_daily_weighting) +
                  Number(r.midterm_exams_weighting) +
                  Number(r.semester_exams_weighting);

                if (r.total_daily) {
                  checkTotalSub =
                    Number(r.daily_weighting) + Number(r.homework_weighting);
                  if (checkTotalSub != 100) {
                    r.totalCombineError = true;
                    r.homework = true;
                    r.daily = true;
                  }
                  condition.push(checkTotal == 100 && checkTotalSub == 100);
                } else {
                  condition.push(checkTotal == 100);
                }
              } else {
                checkTotal = this.totalWeight(i);
                condition.push(checkTotal == 100);
              }
            }
          })
        );

        if (!condition.includes(false)) {
          const queryData = [];

          if (this.formType == "weight") {
            this.data.ki.map(r => {
              queryData.push({
                id: r.id ? r.id : null,
                competencies: r.competencies,
                formula: r.has_weighting ? r.formula : "",
                combined: r.formula == 0 ? r.total_daily_weighting : 0,
                daily: r.formula == 0 ? r.daily_weighting : r.daily_2_weighting,
                homework:
                  r.formula == 0
                    ? r.homework_weighting
                    : r.homework_2_weighting,
                midterm:
                  r.formula == 0
                    ? r.midterm_exams_weighting
                    : r.midterm_exams_2_weighting,
                semester:
                  r.formula == 0
                    ? r.semester_exams_weighting
                    : r.semester_exams_2_weighting
              });
            });
          } else {
            this.data.ki.map((r, i) => {
              queryData.push({
                competencies: r.competencies,
                desc: r.desc,
                key_ki: i + 1,
                formula: r.formula,
                bobot: r.has_weighting
                  ? [
                      {
                        key: 1,
                        value: this.setBobot(
                          r.formula == 0
                            ? r.daily_weighting
                            : r.daily_2_weighting
                        )
                      },
                      {
                        key: 2,
                        value: this.setBobot(
                          r.formula == 0
                            ? r.homework_weighting
                            : r.homework_2_weighting
                        )
                      },
                      {
                        key: 3,
                        value: this.setBobot(
                          r.formula == 0
                            ? r.midterm_exams_weighting
                            : r.midterm_exams_2_weighting
                        )
                      },
                      {
                        key: 4,
                        value: this.setBobot(
                          r.formula == 0
                            ? r.semester_exams_weighting
                            : r.semester_exams_2_weighting
                        )
                      }
                    ]
                  : []
              });
            });
          }

          this.loadingSave = true;
          save_competencies(
            {
              school_year: this.data.school_year,
              grade: this.data.grade,
              lesson: this.data.subject,
              data: queryData
            },
            this.formType
          )
            .then(res => {
              if (res.data.code) {
                let message = this.$i18n.t(
                  "core_competencies.save_ki_edit_success"
                );
                if (this.formType == "weight") {
                  message = this.$i18n.t(
                    "core_competencies.save_weight_success"
                  );
                }
                this.$store.commit("CALL_SNACKBAR", {
                  msg: message,
                  color: "success"
                });
                this.closeDialog();
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.data.message,
                  color: "error"
                });
              }
              this.loadingSave = false;
            })
            .catch(() => {
              this.loadingSave = false;
            });
        } else {
          if (condition.includes(false)) {
            element.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }
        }
      } else {
        this.$nextTick(() => {
          element.scrollIntoView({
            behavior: "smooth",
            block: "center"
          });
        });
      }
    }
  }
};
</script>
